export const paths = {
    home: '/dashboard/products',
    auth: {
        signIn: '/sign-in',
    },
    dashboard: {
        articles: {
            list: '/dashboard/articles',
            create: '/dashboard/articles/create',
            details: (articleId: string) => `/dashboard/articles/${articleId}`,
        },
        overview: '/dashboard',
        settings: {
            account: '/dashboard/settings/account',
            billing: '/dashboard/settings/billing',
            integrations: '/dashboard/settings/integrations',
            notifications: '/dashboard/settings/notifications',
            security: '/dashboard/settings/security',
            team: '/dashboard/settings/team',
        },
        documents: {
            list: '/dashboard/documents',
            create: '/dashboard/documents/create',
            details: (documentId: string) => `/dashboard/documents/${documentId}`,
        },
        productGroups: {
            list: '/dashboard/product-groups',
            create: '/dashboard/product-groups/create',
            details: (productGroupId: string) => `/dashboard/product-groups/${productGroupId}`,
        },
        users: {
            list: '/dashboard/users',
            create: '/dashboard/users/create',
            details: (userId: string) => `/dashboard/users/${userId}`,
        },
        products: {
            list: '/dashboard/products',
            create: '/dashboard/products/create',
            preview: (productId: string) => `/dashboard/products?previewId=${productId}`,
            details: (productId: string) => `/dashboard/products/${productId}`,
        },
    },
    components: {
        index: '/components',
        buttons: '/components/buttons',
        charts: '/components/charts',
        colors: '/components/colors',
        detailLists: '/components/detail-lists',
        forms: '/components/forms',
        gridLists: '/components/grid-lists',
        groupedLists: '/components/grouped-lists',
        inputs: '/components/inputs',
        modals: '/components/modals',
        quickStats: '/components/quick-stats',
        tables: '/components/tables',
        typography: '/components/typography',
    },
    notAuthorized: '/errors/not-authorized',
    notFound: '/errors/not-found',
} as const;
