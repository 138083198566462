import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type AdditionalItem = {
  __typename?: 'AdditionalItem';
  configurationBlockCode: Scalars['String']['output'];
  cost: Scalars['Int']['output'];
  discount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
};

export type AdditionalItemCreateInput = {
  configurationBlockCode: Scalars['String']['input'];
  cost: Scalars['Int']['input'];
  discount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type AdditionalItemObjectEqualityInput = {
  configurationBlockCode: Scalars['String']['input'];
  cost: Scalars['Int']['input'];
  discount: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type Article = {
  __typename?: 'Article';
  cost: Scalars['Int']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  listPrice: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type ArticleCreateInput = {
  cost: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  listPrice: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BuildingBlock = {
  __typename?: 'BuildingBlock';
  code: Scalars['String']['output'];
  mappingValueFormula: Scalars['String']['output'];
  mappingValues: Array<MappingValue>;
  name: Scalars['String']['output'];
  quantityFormula: Scalars['String']['output'];
  rules: Array<Rule>;
  sortOrder: Scalars['Int']['output'];
};

export type BuildingBlockCreateInput = {
  code: Scalars['String']['input'];
  mappingValueFormula: Scalars['String']['input'];
  mappingValues?: InputMaybe<Array<MappingValueCreateInput>>;
  name: Scalars['String']['input'];
  quantityFormula: Scalars['String']['input'];
  rules?: InputMaybe<Array<RuleCreateInput>>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type BuildingBlockObjectEqualityInput = {
  code: Scalars['String']['input'];
  mappingValueFormula: Scalars['String']['input'];
  mappingValues?: InputMaybe<Array<MappingValueObjectEqualityInput>>;
  name: Scalars['String']['input'];
  quantityFormula: Scalars['String']['input'];
  rules?: InputMaybe<Array<RuleObjectEqualityInput>>;
  sortOrder: Scalars['Int']['input'];
};

export type ConfigurationBlock = {
  __typename?: 'ConfigurationBlock';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sections: Array<Section>;
  sortOrder: Scalars['Int']['output'];
};

export type ConfigurationBlockCreateInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sections?: InputMaybe<Array<SectionCreateInput>>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type ConfigurationBlockObjectEqualityInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sections?: InputMaybe<Array<SectionObjectEqualityInput>>;
  sortOrder: Scalars['Int']['input'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productsAvailableFor: Array<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type DocumentCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productsAvailableFor?: InputMaybe<Array<Scalars['String']['input']>>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GetPasswordModel = {
  __typename?: 'GetPasswordModel';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type KeyValueCreateInput = {
  key: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type KeyValueObjectEqualityInput = {
  key: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type MappingValue = {
  __typename?: 'MappingValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MappingValueCreateInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MappingValueObjectEqualityInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createArticle: Scalars['Boolean']['output'];
  createDocument: Scalars['Boolean']['output'];
  createOpportunity: Scalars['String']['output'];
  createProduct: Scalars['String']['output'];
  createProductGroup: Scalars['Boolean']['output'];
  createQuote: Scalars['String']['output'];
  createUser: Scalars['Boolean']['output'];
  deleteArticle: Scalars['Boolean']['output'];
  deleteOpportunity: Scalars['Boolean']['output'];
  deleteQuote: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  refreshToken: Scalars['String']['output'];
  setQuoteDate: Scalars['Boolean']['output'];
  updateArticle: Scalars['Boolean']['output'];
  updateDocument: Scalars['Boolean']['output'];
  updateOpportunity: Scalars['String']['output'];
  updateProduct: Scalars['String']['output'];
  updateProductGroup: Scalars['Boolean']['output'];
  updateQuote: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
};


export type MutationCreateArticleArgs = {
  input: ArticleCreateInput;
};


export type MutationCreateDocumentArgs = {
  input: DocumentCreateInput;
};


export type MutationCreateOpportunityArgs = {
  input: OpportunityCreateInput;
};


export type MutationCreateProductArgs = {
  input: ProductCreateInput;
  productGroupId: Scalars['String']['input'];
};


export type MutationCreateProductGroupArgs = {
  input: ProductGroupCreateInput;
};


export type MutationCreateQuoteArgs = {
  input: QuoteCreateInput;
  opportunityId: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteArticleArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOpportunityArgs = {
  opportunityId: Scalars['String']['input'];
};


export type MutationDeleteQuoteArgs = {
  quoteId: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationSetQuoteDateArgs = {
  quoteDate: Scalars['String']['input'];
  quoteId: Scalars['String']['input'];
};


export type MutationUpdateArticleArgs = {
  articleId: Scalars['String']['input'];
  input: ArticleCreateInput;
};


export type MutationUpdateDocumentArgs = {
  documentId: Scalars['String']['input'];
  input: DocumentCreateInput;
};


export type MutationUpdateOpportunityArgs = {
  id: Scalars['String']['input'];
  input: OpportunityUpdateInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['String']['input'];
  input: ProductUpdateInput;
  productGroupId: Scalars['String']['input'];
};


export type MutationUpdateProductGroupArgs = {
  input: ProductGroupCreateInput;
  productGroupId: Scalars['String']['input'];
};


export type MutationUpdateQuoteArgs = {
  id: Scalars['String']['input'];
  input: QuoteUpdateInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: UserUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  _count?: Maybe<OpportunityCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdByUser?: Maybe<User>;
  createdByUserId: Scalars['String']['output'];
  customerName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expectedPriceDetail: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nextQuoteIndex: Scalars['Int']['output'];
  opportunityId: Scalars['String']['output'];
  quotes?: Maybe<Array<Quote>>;
  sectorType: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type OpportunityCount = {
  __typename?: 'OpportunityCount';
  quotes: Scalars['Int']['output'];
};


export type OpportunityCountQuotesArgs = {
  where?: InputMaybe<QuoteWhereInput>;
};

export type OpportunityCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customerName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  expectedPriceDetail: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  nextQuoteIndex?: InputMaybe<Scalars['Int']['input']>;
  sectorType: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type OpportunityUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expectedPriceDetail?: InputMaybe<Scalars['String']['input']>;
  nextQuoteIndex?: InputMaybe<Scalars['Int']['input']>;
  sectorType?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type OpportunityWhereInput = {
  AND?: InputMaybe<Array<OpportunityWhereInput>>;
  NOT?: InputMaybe<Array<OpportunityWhereInput>>;
  OR?: InputMaybe<Array<OpportunityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerName?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  expectedPriceDetail?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  nextQuoteIndex?: InputMaybe<IntFilter>;
  sectorType?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Option = {
  __typename?: 'Option';
  name: Scalars['String']['output'];
  rules: Array<Rule>;
  value: Scalars['String']['output'];
};

export type OptionCreateInput = {
  name: Scalars['String']['input'];
  rules?: InputMaybe<Array<RuleCreateInput>>;
  value: Scalars['String']['input'];
};

export type OptionObjectEqualityInput = {
  name: Scalars['String']['input'];
  rules?: InputMaybe<Array<RuleObjectEqualityInput>>;
  value: Scalars['String']['input'];
};

export type PaginationInput = {
  count: Scalars['Float']['input'];
  cursor?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['String']['input']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  code: Scalars['String']['output'];
  defaultValue?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  numberMax?: Maybe<Scalars['Float']['output']>;
  numberMin?: Maybe<Scalars['Float']['output']>;
  options: Array<Option>;
  rules: Array<Rule>;
  sortOrder: Scalars['Int']['output'];
  type: ParameterType;
};

export type ParameterCreateInput = {
  code: Scalars['String']['input'];
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberMax?: InputMaybe<Scalars['Float']['input']>;
  numberMin?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<Array<OptionCreateInput>>;
  rules?: InputMaybe<Array<RuleCreateInput>>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  type: ParameterType;
};

export type ParameterObjectEqualityInput = {
  code: Scalars['String']['input'];
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberMax?: InputMaybe<Scalars['Float']['input']>;
  numberMin?: InputMaybe<Scalars['Float']['input']>;
  options?: InputMaybe<Array<OptionObjectEqualityInput>>;
  rules?: InputMaybe<Array<RuleObjectEqualityInput>>;
  sortOrder: Scalars['Int']['input'];
  type: ParameterType;
};

export enum ParameterType {
  Boolean = 'boolean',
  Dropdown = 'dropdown',
  Number = 'number',
  String = 'string',
  StringLarge = 'stringLarge'
}

export type Product = {
  __typename?: 'Product';
  active: Scalars['Boolean']['output'];
  configurationBlocks: Array<ConfigurationBlock>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productGroupId: Scalars['String']['output'];
  productSettings: Array<ProductSetting>;
  referencedProductConfiguration?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type ProductCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockCreateInput>>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productSettings?: InputMaybe<Array<ProductSettingCreateInput>>;
  referencedProductConfiguration?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ProductCreateManyProductGroupInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockCreateInput>>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productSettings?: InputMaybe<Array<ProductSettingCreateInput>>;
  referencedProductConfiguration?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ProductCreateManyProductGroupInputEnvelope = {
  data: Array<ProductCreateManyProductGroupInput>;
};

export type ProductCreateNestedManyWithoutProductGroupInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutProductGroupInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutProductGroupInput>>;
  createMany?: InputMaybe<ProductCreateManyProductGroupInputEnvelope>;
};

export type ProductCreateOrConnectWithoutProductGroupInput = {
  create: ProductCreateWithoutProductGroupInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutProductGroupInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockCreateInput>>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productSettings?: InputMaybe<Array<ProductSettingCreateInput>>;
  referencedProductConfiguration?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ProductGroup = {
  __typename?: 'ProductGroup';
  _count?: Maybe<ProductGroupCount>;
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type ProductGroupCount = {
  __typename?: 'ProductGroupCount';
  products: Scalars['Int']['output'];
};


export type ProductGroupCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductGroupCreateInput = {
  active: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutProductGroupInput>;
  sortOrder: Scalars['Int']['input'];
};

export type ProductSetting = {
  __typename?: 'ProductSetting';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ProductSettingCreateInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ProductSettingObjectEqualityInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ProductSnapshot = {
  __typename?: 'ProductSnapshot';
  active: Scalars['Boolean']['output'];
  configurationBlocks: Array<ConfigurationBlock>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  productGroupId: Scalars['String']['output'];
  productSettings: Array<ProductSetting>;
  referencedProductConfiguration?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type ProductSnapshotCreateInput = {
  active: Scalars['Boolean']['input'];
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockCreateInput>>;
  createdAt: Scalars['DateTimeISO']['input'];
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productGroupId: Scalars['String']['input'];
  productSettings?: InputMaybe<Array<ProductSettingCreateInput>>;
  referencedProductConfiguration?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  sortOrder: Scalars['Int']['input'];
  updatedAt: Scalars['DateTimeISO']['input'];
};

export type ProductSnapshotObjectEqualityInput = {
  active: Scalars['Boolean']['input'];
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockObjectEqualityInput>>;
  createdAt: Scalars['DateTimeISO']['input'];
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productGroupId: Scalars['String']['input'];
  productSettings?: InputMaybe<Array<ProductSettingObjectEqualityInput>>;
  referencedProductConfiguration?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  sortOrder: Scalars['Int']['input'];
  updatedAt: Scalars['DateTimeISO']['input'];
};

export type ProductUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockCreateInput>>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productSettings?: InputMaybe<Array<ProductSettingCreateInput>>;
  referencedProductConfiguration?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockObjectEqualityInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  productGroupId?: InputMaybe<StringFilter>;
  productSettings?: InputMaybe<Array<ProductSettingObjectEqualityInput>>;
  referencedProductConfiguration?: InputMaybe<StringNullableFilter>;
  sku?: InputMaybe<StringFilter>;
  sortOrder?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductWhereUniqueInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  configurationBlocks?: InputMaybe<Array<ConfigurationBlockObjectEqualityInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  productGroupId?: InputMaybe<StringFilter>;
  productSettings?: InputMaybe<Array<ProductSettingObjectEqualityInput>>;
  referencedProductConfiguration?: InputMaybe<StringNullableFilter>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Query = {
  __typename?: 'Query';
  articles: Array<Article>;
  getActiveProductGroups: Array<ProductGroup>;
  getAll: Array<User>;
  getAllArticles: Array<Article>;
  getAllDocuments: Array<Document>;
  getAllProductGroups: Array<ProductGroup>;
  getAllQuotes: Array<Quote>;
  getArticleBySku: Article;
  getAvailableDocuments: Array<Document>;
  getDocumentByDocumentId: Document;
  getOne: User;
  getProduct: Product;
  getProductGroupById: ProductGroup;
  getWebToken: GetPasswordModel;
  listAllOpportunities: Array<Opportunity>;
  listOpportunities: Array<Opportunity>;
  listProducts: Array<Product>;
  opportunityByOpportunityId: Opportunity;
  product: Product;
  productsByProductGroup: Array<Product>;
  quoteByQuoteId: Quote;
  verifyJsonWebToken?: Maybe<User>;
};


export type QueryArticlesArgs = {
  sku: Array<Scalars['String']['input']>;
};


export type QueryGetActiveProductGroupsArgs = {
  includeProductGroupWithId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetArticleBySkuArgs = {
  sku: Scalars['String']['input'];
};


export type QueryGetAvailableDocumentsArgs = {
  productSku: Scalars['String']['input'];
};


export type QueryGetDocumentByDocumentIdArgs = {
  documentId: Scalars['String']['input'];
};


export type QueryGetOneArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetProductGroupByIdArgs = {
  productGroupId: Scalars['String']['input'];
};


export type QueryGetWebTokenArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type QueryListOpportunitiesArgs = {
  pagination: PaginationInput;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOpportunityByOpportunityIdArgs = {
  opportunityId: Scalars['String']['input'];
};


export type QueryProductArgs = {
  productId: Scalars['String']['input'];
};


export type QueryProductsByProductGroupArgs = {
  productGroupId: Scalars['String']['input'];
};


export type QueryQuoteByQuoteIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryVerifyJsonWebTokenArgs = {
  token: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Quote = {
  __typename?: 'Quote';
  createdAt: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  currencyFactor: Scalars['Float']['output'];
  deliveryTerms: Scalars['String']['output'];
  deliveryTime: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  generalConditions: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  opportunityId: Scalars['String']['output'];
  paymentTerms: Scalars['String']['output'];
  quoteDate?: Maybe<Scalars['DateTimeISO']['output']>;
  quoteId: Scalars['String']['output'];
  quoteRows?: Maybe<Array<QuoteRow>>;
  quoteValidUntilDate?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  validity: Scalars['Int']['output'];
};

export type QuoteCreateInput = {
  currency: Scalars['String']['input'];
  currencyFactor: Scalars['Float']['input'];
  deliveryTerms?: InputMaybe<Scalars['String']['input']>;
  deliveryTime?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  generalConditions?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentTerms?: InputMaybe<Scalars['String']['input']>;
  quoteDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quoteRows?: InputMaybe<Array<QuoteRowCreateInput>>;
  quoteValidUntilDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validity: Scalars['Int']['input'];
};

export type QuoteRow = {
  __typename?: 'QuoteRow';
  additionalItems: Array<AdditionalItem>;
  attachedDocumentsKeys: Array<Scalars['String']['output']>;
  configurationDiscount: Array<KeyValue>;
  configurationQuantity: Array<KeyValue>;
  description?: Maybe<Scalars['String']['output']>;
  productReference?: Maybe<Scalars['String']['output']>;
  productSnapshot: ProductSnapshot;
  savedArticles: Array<SavedArticle>;
  selectedParameters: Array<SelectedParameter>;
  uuid: Scalars['String']['output'];
};

export type QuoteRowCreateInput = {
  additionalItems?: InputMaybe<Array<AdditionalItemCreateInput>>;
  attachedDocumentsKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  configurationDiscount?: InputMaybe<Array<KeyValueCreateInput>>;
  configurationQuantity?: InputMaybe<Array<KeyValueCreateInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  productReference?: InputMaybe<Scalars['String']['input']>;
  productSnapshot: ProductSnapshotCreateInput;
  savedArticles?: InputMaybe<Array<SavedArticleCreateInput>>;
  selectedParameters?: InputMaybe<Array<SelectedParameterCreateInput>>;
  uuid: Scalars['String']['input'];
};

export type QuoteRowObjectEqualityInput = {
  additionalItems?: InputMaybe<Array<AdditionalItemObjectEqualityInput>>;
  attachedDocumentsKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  configurationDiscount?: InputMaybe<Array<KeyValueObjectEqualityInput>>;
  configurationQuantity?: InputMaybe<Array<KeyValueObjectEqualityInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  productReference?: InputMaybe<Scalars['String']['input']>;
  productSnapshot: ProductSnapshotObjectEqualityInput;
  savedArticles?: InputMaybe<Array<SavedArticleObjectEqualityInput>>;
  selectedParameters?: InputMaybe<Array<SelectedParameterObjectEqualityInput>>;
  uuid: Scalars['String']['input'];
};

export type QuoteUpdateInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  currencyFactor?: InputMaybe<Scalars['Float']['input']>;
  deliveryTerms?: InputMaybe<Scalars['String']['input']>;
  deliveryTime?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  generalConditions?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentTerms?: InputMaybe<Scalars['String']['input']>;
  quoteDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  quoteRows?: InputMaybe<Array<QuoteRowCreateInput>>;
  quoteValidUntilDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  validity?: InputMaybe<Scalars['Int']['input']>;
};

export type QuoteWhereInput = {
  AND?: InputMaybe<Array<QuoteWhereInput>>;
  NOT?: InputMaybe<Array<QuoteWhereInput>>;
  OR?: InputMaybe<Array<QuoteWhereInput>>;
  currency?: InputMaybe<StringFilter>;
  currencyFactor?: InputMaybe<FloatFilter>;
  deliveryTerms?: InputMaybe<StringFilter>;
  deliveryTime?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  generalConditions?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notes?: InputMaybe<StringFilter>;
  paymentTerms?: InputMaybe<StringFilter>;
  quoteDate?: InputMaybe<DateTimeNullableFilter>;
  quoteRows?: InputMaybe<Array<QuoteRowObjectEqualityInput>>;
  quoteValidUntilDate?: InputMaybe<DateTimeNullableFilter>;
  validity?: InputMaybe<IntFilter>;
};

export type Rule = {
  __typename?: 'Rule';
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  operator: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  property?: Maybe<Scalars['String']['output']>;
  type: RuleType;
  value?: Maybe<Scalars['String']['output']>;
};

export type RuleCreateInput = {
  id: Scalars['String']['input'];
  level: Scalars['Int']['input'];
  operator?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<RuleType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type RuleObjectEqualityInput = {
  id: Scalars['String']['input'];
  level: Scalars['Int']['input'];
  operator: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<Scalars['String']['input']>;
  type: RuleType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum RuleType {
  Comparator = 'comparator',
  Logical = 'logical'
}

export type SavedArticle = {
  __typename?: 'SavedArticle';
  buildingBlockCode: Scalars['String']['output'];
  cost: Scalars['Int']['output'];
  discountPercentage: Scalars['Int']['output'];
  listPrice: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  qty: Scalars['Int']['output'];
  sectionCode: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  useDefaultDiscount: Scalars['Boolean']['output'];
};

export type SavedArticleCreateInput = {
  buildingBlockCode: Scalars['String']['input'];
  cost: Scalars['Int']['input'];
  discountPercentage?: InputMaybe<Scalars['Int']['input']>;
  listPrice: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  qty: Scalars['Int']['input'];
  sectionCode: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  useDefaultDiscount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SavedArticleObjectEqualityInput = {
  buildingBlockCode: Scalars['String']['input'];
  cost: Scalars['Int']['input'];
  discountPercentage: Scalars['Int']['input'];
  listPrice: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  qty: Scalars['Int']['input'];
  sectionCode: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  useDefaultDiscount: Scalars['Boolean']['input'];
};

export type Section = {
  __typename?: 'Section';
  buildingBlocks: Array<BuildingBlock>;
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parameters: Array<Parameter>;
  rules: Array<Rule>;
  sortOrder: Scalars['Int']['output'];
};

export type SectionCreateInput = {
  buildingBlocks?: InputMaybe<Array<BuildingBlockCreateInput>>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parameters?: InputMaybe<Array<ParameterCreateInput>>;
  rules?: InputMaybe<Array<RuleCreateInput>>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type SectionObjectEqualityInput = {
  buildingBlocks?: InputMaybe<Array<BuildingBlockObjectEqualityInput>>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parameters?: InputMaybe<Array<ParameterObjectEqualityInput>>;
  rules?: InputMaybe<Array<RuleObjectEqualityInput>>;
  sortOrder: Scalars['Int']['input'];
};

export type SelectedParameter = {
  __typename?: 'SelectedParameter';
  code: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SelectedParameterCreateInput = {
  code: Scalars['String']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SelectedParameterObjectEqualityInput = {
  code: Scalars['String']['input'];
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isSet?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type UserCount = {
  __typename?: 'UserCount';
  opportunities: Scalars['Int']['output'];
};


export type UserCountOpportunitiesArgs = {
  where?: InputMaybe<OpportunityWhereInput>;
};

export type UserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type GetAllArticlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllArticlesQuery = { __typename?: 'Query', getAllArticles: Array<{ __typename?: 'Article', id: string, name: string, sku: string, cost: number, listPrice: number, createdAt: any, updatedAt: any }> };

export type GetArticleBySkuQueryVariables = Exact<{
  sku: Scalars['String']['input'];
}>;


export type GetArticleBySkuQuery = { __typename?: 'Query', getArticleBySku: { __typename?: 'Article', id: string, name: string, sku: string, cost: number, listPrice: number, createdAt: any, updatedAt: any } };

export type CreateArticleMutationVariables = Exact<{
  input: ArticleCreateInput;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle: boolean };

export type UpdateArticleMutationVariables = Exact<{
  articleId: Scalars['String']['input'];
  input: ArticleCreateInput;
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticle: boolean };

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteArticleMutation = { __typename?: 'Mutation', deleteArticle: boolean };

export type GetAllDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDocumentsQuery = { __typename?: 'Query', getAllDocuments: Array<{ __typename?: 'Document', id: string, name: string, key: string, type: string, link: string, productsAvailableFor: Array<string>, createdAt: any, updatedAt: any }> };

export type GetDocumentByDocumentIdQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;


export type GetDocumentByDocumentIdQuery = { __typename?: 'Query', getDocumentByDocumentId: { __typename?: 'Document', id: string, name: string, key: string, type: string, link: string, productsAvailableFor: Array<string>, createdAt: any, updatedAt: any } };

export type CreateDocumentMutationVariables = Exact<{
  input: DocumentCreateInput;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument: boolean };

export type UpdateDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
  input: DocumentCreateInput;
}>;


export type UpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: boolean };

export type ListProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListProductsQuery = { __typename?: 'Query', listProducts: Array<{ __typename?: 'Product', id: string, name: string, sku: string, active: boolean, productGroupId: string, createdAt: any }> };

export type RulePartsFragment = { __typename?: 'Rule', id: string, type: RuleType, property?: string | null, operator: string, value?: string | null, parentId?: string | null, level: number };

export type GetProductQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetProductQuery = { __typename?: 'Query', getProduct: { __typename?: 'Product', id: string, name: string, active: boolean, sortOrder: number, sku: string, image?: string | null, referencedProductConfiguration?: string | null, productGroupId: string, createdAt: any, updatedAt: any, productSettings: Array<{ __typename?: 'ProductSetting', key: string, value: string }>, configurationBlocks: Array<{ __typename?: 'ConfigurationBlock', name: string, description?: string | null, code: string, sortOrder: number, sections: Array<{ __typename?: 'Section', name: string, description?: string | null, code: string, sortOrder: number, rules: Array<{ __typename?: 'Rule', id: string, type: RuleType, property?: string | null, operator: string, value?: string | null, parentId?: string | null, level: number }>, parameters: Array<{ __typename?: 'Parameter', name: string, type: ParameterType, code: string, numberMin?: number | null, numberMax?: number | null, defaultValue?: string | null, sortOrder: number, rules: Array<{ __typename?: 'Rule', id: string, type: RuleType, property?: string | null, operator: string, value?: string | null, parentId?: string | null, level: number }>, options: Array<{ __typename?: 'Option', name: string, value: string, rules: Array<{ __typename?: 'Rule', id: string, type: RuleType, property?: string | null, operator: string, value?: string | null, parentId?: string | null, level: number }> }> }>, buildingBlocks: Array<{ __typename?: 'BuildingBlock', name: string, code: string, mappingValueFormula: string, quantityFormula: string, sortOrder: number, rules: Array<{ __typename?: 'Rule', id: string, type: RuleType, property?: string | null, operator: string, value?: string | null, parentId?: string | null, level: number }>, mappingValues: Array<{ __typename?: 'MappingValue', key: string, value: string }> }> }> }> } };

export type CreateProductMutationVariables = Exact<{
  input: ProductCreateInput;
  productGroupId: Scalars['String']['input'];
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: string };

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: ProductUpdateInput;
  productGroupId: Scalars['String']['input'];
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: string };

export type CreateProductGroupMutationVariables = Exact<{
  input: ProductGroupCreateInput;
}>;


export type CreateProductGroupMutation = { __typename?: 'Mutation', createProductGroup: boolean };

export type UpdateProductGroupMutationVariables = Exact<{
  productGroupId: Scalars['String']['input'];
  input: ProductGroupCreateInput;
}>;


export type UpdateProductGroupMutation = { __typename?: 'Mutation', updateProductGroup: boolean };

export type GetAllProductGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllProductGroupsQuery = { __typename?: 'Query', getAllProductGroups: Array<{ __typename?: 'ProductGroup', active: boolean, id: string, name: string, sortOrder: number }> };

export type GetActiveProductGroupsQueryVariables = Exact<{
  includeProductGroupWithId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetActiveProductGroupsQuery = { __typename?: 'Query', getActiveProductGroups: Array<{ __typename?: 'ProductGroup', active: boolean, id: string, name: string, sortOrder: number }> };

export type GetProductGroupQueryVariables = Exact<{
  productGroupId: Scalars['String']['input'];
}>;


export type GetProductGroupQuery = { __typename?: 'Query', getProductGroupById: { __typename?: 'ProductGroup', active: boolean, id: string, name: string, sortOrder: number } };

export type UserFragment = { __typename?: 'User', id: string, name: string, email: string, role: string, createdAt: any, updatedAt: any };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', getAll: Array<{ __typename?: 'User', id: string, name: string, email: string, role: string, createdAt: any, updatedAt: any }> };

export type VerifyCredentialsQueryVariables = Exact<{
  password: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type VerifyCredentialsQuery = { __typename?: 'Query', getWebToken: { __typename?: 'GetPasswordModel', accessToken: string, refreshToken: string } };

export type VerifyJsonWebTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type VerifyJsonWebTokenQuery = { __typename?: 'Query', verifyJsonWebToken?: { __typename?: 'User', id: string, name: string, email: string, role: string } | null };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: string };

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: boolean };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type UserByIdQuery = { __typename?: 'Query', getOne: { __typename?: 'User', id: string, name: string, email: string, role: string, createdAt: any, updatedAt: any } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: boolean };

export const RulePartsFragmentDoc = gql`
    fragment RuleParts on Rule {
  id
  type
  property
  operator
  value
  parentId
  level
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  role
  createdAt
  updatedAt
}
    `;
export const GetAllArticlesDocument = gql`
    query GetAllArticles {
  getAllArticles {
    id
    name
    sku
    cost
    listPrice
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAllArticlesQuery__
 *
 * To run a query within a React component, call `useGetAllArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllArticlesQuery, GetAllArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllArticlesQuery, GetAllArticlesQueryVariables>(GetAllArticlesDocument, options);
      }
export function useGetAllArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllArticlesQuery, GetAllArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllArticlesQuery, GetAllArticlesQueryVariables>(GetAllArticlesDocument, options);
        }
export function useGetAllArticlesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllArticlesQuery, GetAllArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllArticlesQuery, GetAllArticlesQueryVariables>(GetAllArticlesDocument, options);
        }
export type GetAllArticlesQueryHookResult = ReturnType<typeof useGetAllArticlesQuery>;
export type GetAllArticlesLazyQueryHookResult = ReturnType<typeof useGetAllArticlesLazyQuery>;
export type GetAllArticlesSuspenseQueryHookResult = ReturnType<typeof useGetAllArticlesSuspenseQuery>;
export type GetAllArticlesQueryResult = Apollo.QueryResult<GetAllArticlesQuery, GetAllArticlesQueryVariables>;
export const GetArticleBySkuDocument = gql`
    query GetArticleBySku($sku: String!) {
  getArticleBySku(sku: $sku) {
    id
    name
    sku
    cost
    listPrice
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetArticleBySkuQuery__
 *
 * To run a query within a React component, call `useGetArticleBySkuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleBySkuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleBySkuQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetArticleBySkuQuery(baseOptions: Apollo.QueryHookOptions<GetArticleBySkuQuery, GetArticleBySkuQueryVariables> & ({ variables: GetArticleBySkuQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleBySkuQuery, GetArticleBySkuQueryVariables>(GetArticleBySkuDocument, options);
      }
export function useGetArticleBySkuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleBySkuQuery, GetArticleBySkuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleBySkuQuery, GetArticleBySkuQueryVariables>(GetArticleBySkuDocument, options);
        }
export function useGetArticleBySkuSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArticleBySkuQuery, GetArticleBySkuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArticleBySkuQuery, GetArticleBySkuQueryVariables>(GetArticleBySkuDocument, options);
        }
export type GetArticleBySkuQueryHookResult = ReturnType<typeof useGetArticleBySkuQuery>;
export type GetArticleBySkuLazyQueryHookResult = ReturnType<typeof useGetArticleBySkuLazyQuery>;
export type GetArticleBySkuSuspenseQueryHookResult = ReturnType<typeof useGetArticleBySkuSuspenseQuery>;
export type GetArticleBySkuQueryResult = Apollo.QueryResult<GetArticleBySkuQuery, GetArticleBySkuQueryVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($input: ArticleCreateInput!) {
  createArticle(input: $input)
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($articleId: String!, $input: ArticleCreateInput!) {
  updateArticle(articleId: $articleId, input: $input)
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const DeleteArticleDocument = gql`
    mutation DeleteArticle($id: String!) {
  deleteArticle(id: $id)
}
    `;
export type DeleteArticleMutationFn = Apollo.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, options);
      }
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<DeleteArticleMutation, DeleteArticleMutationVariables>;
export const GetAllDocumentsDocument = gql`
    query GetAllDocuments {
  getAllDocuments {
    id
    name
    key
    type
    link
    productsAvailableFor
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetAllDocumentsQuery__
 *
 * To run a query within a React component, call `useGetAllDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>(GetAllDocumentsDocument, options);
      }
export function useGetAllDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>(GetAllDocumentsDocument, options);
        }
export function useGetAllDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>(GetAllDocumentsDocument, options);
        }
export type GetAllDocumentsQueryHookResult = ReturnType<typeof useGetAllDocumentsQuery>;
export type GetAllDocumentsLazyQueryHookResult = ReturnType<typeof useGetAllDocumentsLazyQuery>;
export type GetAllDocumentsSuspenseQueryHookResult = ReturnType<typeof useGetAllDocumentsSuspenseQuery>;
export type GetAllDocumentsQueryResult = Apollo.QueryResult<GetAllDocumentsQuery, GetAllDocumentsQueryVariables>;
export const GetDocumentByDocumentIdDocument = gql`
    query GetDocumentByDocumentId($documentId: String!) {
  getDocumentByDocumentId(documentId: $documentId) {
    id
    name
    key
    type
    link
    productsAvailableFor
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetDocumentByDocumentIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByDocumentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByDocumentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByDocumentIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentByDocumentIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables> & ({ variables: GetDocumentByDocumentIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables>(GetDocumentByDocumentIdDocument, options);
      }
export function useGetDocumentByDocumentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables>(GetDocumentByDocumentIdDocument, options);
        }
export function useGetDocumentByDocumentIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables>(GetDocumentByDocumentIdDocument, options);
        }
export type GetDocumentByDocumentIdQueryHookResult = ReturnType<typeof useGetDocumentByDocumentIdQuery>;
export type GetDocumentByDocumentIdLazyQueryHookResult = ReturnType<typeof useGetDocumentByDocumentIdLazyQuery>;
export type GetDocumentByDocumentIdSuspenseQueryHookResult = ReturnType<typeof useGetDocumentByDocumentIdSuspenseQuery>;
export type GetDocumentByDocumentIdQueryResult = Apollo.QueryResult<GetDocumentByDocumentIdQuery, GetDocumentByDocumentIdQueryVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($input: DocumentCreateInput!) {
  createDocument(input: $input)
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($documentId: String!, $input: DocumentCreateInput!) {
  updateDocument(documentId: $documentId, input: $input)
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const ListProductsDocument = gql`
    query ListProducts {
  listProducts {
    id
    name
    sku
    active
    productGroupId
    createdAt
  }
}
    `;

/**
 * __useListProductsQuery__
 *
 * To run a query within a React component, call `useListProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProductsQuery(baseOptions?: Apollo.QueryHookOptions<ListProductsQuery, ListProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options);
      }
export function useListProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProductsQuery, ListProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options);
        }
export function useListProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListProductsQuery, ListProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListProductsQuery, ListProductsQueryVariables>(ListProductsDocument, options);
        }
export type ListProductsQueryHookResult = ReturnType<typeof useListProductsQuery>;
export type ListProductsLazyQueryHookResult = ReturnType<typeof useListProductsLazyQuery>;
export type ListProductsSuspenseQueryHookResult = ReturnType<typeof useListProductsSuspenseQuery>;
export type ListProductsQueryResult = Apollo.QueryResult<ListProductsQuery, ListProductsQueryVariables>;
export const GetProductDocument = gql`
    query GetProduct($id: String!) {
  getProduct(id: $id) {
    id
    name
    active
    sortOrder
    sku
    image
    productSettings {
      key
      value
    }
    referencedProductConfiguration
    configurationBlocks {
      name
      description
      code
      sortOrder
      sections {
        name
        description
        code
        sortOrder
        rules {
          ...RuleParts
        }
        parameters {
          name
          type
          code
          numberMin
          numberMax
          defaultValue
          sortOrder
          rules {
            ...RuleParts
          }
          options {
            name
            value
            rules {
              ...RuleParts
            }
          }
        }
        buildingBlocks {
          name
          code
          mappingValueFormula
          quantityFormula
          sortOrder
          rules {
            ...RuleParts
          }
          mappingValues {
            key
            value
          }
        }
      }
    }
    productGroupId
    createdAt
    updatedAt
  }
}
    ${RulePartsFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables> & ({ variables: GetProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export function useGetProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductSuspenseQueryHookResult = ReturnType<typeof useGetProductSuspenseQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: ProductCreateInput!, $productGroupId: String!) {
  createProduct(input: $input, productGroupId: $productGroupId)
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: String!, $input: ProductUpdateInput!, $productGroupId: String!) {
  updateProduct(id: $id, input: $input, productGroupId: $productGroupId)
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const CreateProductGroupDocument = gql`
    mutation CreateProductGroup($input: ProductGroupCreateInput!) {
  createProductGroup(input: $input)
}
    `;
export type CreateProductGroupMutationFn = Apollo.MutationFunction<CreateProductGroupMutation, CreateProductGroupMutationVariables>;

/**
 * __useCreateProductGroupMutation__
 *
 * To run a mutation, you first call `useCreateProductGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductGroupMutation, { data, loading, error }] = useCreateProductGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductGroupMutation, CreateProductGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductGroupMutation, CreateProductGroupMutationVariables>(CreateProductGroupDocument, options);
      }
export type CreateProductGroupMutationHookResult = ReturnType<typeof useCreateProductGroupMutation>;
export type CreateProductGroupMutationResult = Apollo.MutationResult<CreateProductGroupMutation>;
export type CreateProductGroupMutationOptions = Apollo.BaseMutationOptions<CreateProductGroupMutation, CreateProductGroupMutationVariables>;
export const UpdateProductGroupDocument = gql`
    mutation UpdateProductGroup($productGroupId: String!, $input: ProductGroupCreateInput!) {
  updateProductGroup(productGroupId: $productGroupId, input: $input)
}
    `;
export type UpdateProductGroupMutationFn = Apollo.MutationFunction<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>;

/**
 * __useUpdateProductGroupMutation__
 *
 * To run a mutation, you first call `useUpdateProductGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductGroupMutation, { data, loading, error }] = useUpdateProductGroupMutation({
 *   variables: {
 *      productGroupId: // value for 'productGroupId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>(UpdateProductGroupDocument, options);
      }
export type UpdateProductGroupMutationHookResult = ReturnType<typeof useUpdateProductGroupMutation>;
export type UpdateProductGroupMutationResult = Apollo.MutationResult<UpdateProductGroupMutation>;
export type UpdateProductGroupMutationOptions = Apollo.BaseMutationOptions<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>;
export const GetAllProductGroupsDocument = gql`
    query GetAllProductGroups {
  getAllProductGroups {
    active
    id
    name
    sortOrder
  }
}
    `;

/**
 * __useGetAllProductGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>(GetAllProductGroupsDocument, options);
      }
export function useGetAllProductGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>(GetAllProductGroupsDocument, options);
        }
export function useGetAllProductGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>(GetAllProductGroupsDocument, options);
        }
export type GetAllProductGroupsQueryHookResult = ReturnType<typeof useGetAllProductGroupsQuery>;
export type GetAllProductGroupsLazyQueryHookResult = ReturnType<typeof useGetAllProductGroupsLazyQuery>;
export type GetAllProductGroupsSuspenseQueryHookResult = ReturnType<typeof useGetAllProductGroupsSuspenseQuery>;
export type GetAllProductGroupsQueryResult = Apollo.QueryResult<GetAllProductGroupsQuery, GetAllProductGroupsQueryVariables>;
export const GetActiveProductGroupsDocument = gql`
    query GetActiveProductGroups($includeProductGroupWithId: String) {
  getActiveProductGroups(includeProductGroupWithId: $includeProductGroupWithId) {
    active
    id
    name
    sortOrder
  }
}
    `;

/**
 * __useGetActiveProductGroupsQuery__
 *
 * To run a query within a React component, call `useGetActiveProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveProductGroupsQuery({
 *   variables: {
 *      includeProductGroupWithId: // value for 'includeProductGroupWithId'
 *   },
 * });
 */
export function useGetActiveProductGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>(GetActiveProductGroupsDocument, options);
      }
export function useGetActiveProductGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>(GetActiveProductGroupsDocument, options);
        }
export function useGetActiveProductGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>(GetActiveProductGroupsDocument, options);
        }
export type GetActiveProductGroupsQueryHookResult = ReturnType<typeof useGetActiveProductGroupsQuery>;
export type GetActiveProductGroupsLazyQueryHookResult = ReturnType<typeof useGetActiveProductGroupsLazyQuery>;
export type GetActiveProductGroupsSuspenseQueryHookResult = ReturnType<typeof useGetActiveProductGroupsSuspenseQuery>;
export type GetActiveProductGroupsQueryResult = Apollo.QueryResult<GetActiveProductGroupsQuery, GetActiveProductGroupsQueryVariables>;
export const GetProductGroupDocument = gql`
    query GetProductGroup($productGroupId: String!) {
  getProductGroupById(productGroupId: $productGroupId) {
    active
    id
    name
    sortOrder
  }
}
    `;

/**
 * __useGetProductGroupQuery__
 *
 * To run a query within a React component, call `useGetProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductGroupQuery({
 *   variables: {
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useGetProductGroupQuery(baseOptions: Apollo.QueryHookOptions<GetProductGroupQuery, GetProductGroupQueryVariables> & ({ variables: GetProductGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductGroupQuery, GetProductGroupQueryVariables>(GetProductGroupDocument, options);
      }
export function useGetProductGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductGroupQuery, GetProductGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductGroupQuery, GetProductGroupQueryVariables>(GetProductGroupDocument, options);
        }
export function useGetProductGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductGroupQuery, GetProductGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductGroupQuery, GetProductGroupQueryVariables>(GetProductGroupDocument, options);
        }
export type GetProductGroupQueryHookResult = ReturnType<typeof useGetProductGroupQuery>;
export type GetProductGroupLazyQueryHookResult = ReturnType<typeof useGetProductGroupLazyQuery>;
export type GetProductGroupSuspenseQueryHookResult = ReturnType<typeof useGetProductGroupSuspenseQuery>;
export type GetProductGroupQueryResult = Apollo.QueryResult<GetProductGroupQuery, GetProductGroupQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  getAll {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export function useGetAllUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersSuspenseQueryHookResult = ReturnType<typeof useGetAllUsersSuspenseQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const VerifyCredentialsDocument = gql`
    query VerifyCredentials($password: String!, $email: String!) {
  getWebToken(password: $password, email: $email) {
    accessToken
    refreshToken
  }
}
    `;

/**
 * __useVerifyCredentialsQuery__
 *
 * To run a query within a React component, call `useVerifyCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCredentialsQuery({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyCredentialsQuery(baseOptions: Apollo.QueryHookOptions<VerifyCredentialsQuery, VerifyCredentialsQueryVariables> & ({ variables: VerifyCredentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyCredentialsQuery, VerifyCredentialsQueryVariables>(VerifyCredentialsDocument, options);
      }
export function useVerifyCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyCredentialsQuery, VerifyCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyCredentialsQuery, VerifyCredentialsQueryVariables>(VerifyCredentialsDocument, options);
        }
export function useVerifyCredentialsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyCredentialsQuery, VerifyCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyCredentialsQuery, VerifyCredentialsQueryVariables>(VerifyCredentialsDocument, options);
        }
export type VerifyCredentialsQueryHookResult = ReturnType<typeof useVerifyCredentialsQuery>;
export type VerifyCredentialsLazyQueryHookResult = ReturnType<typeof useVerifyCredentialsLazyQuery>;
export type VerifyCredentialsSuspenseQueryHookResult = ReturnType<typeof useVerifyCredentialsSuspenseQuery>;
export type VerifyCredentialsQueryResult = Apollo.QueryResult<VerifyCredentialsQuery, VerifyCredentialsQueryVariables>;
export const VerifyJsonWebTokenDocument = gql`
    query VerifyJsonWebToken($token: String!) {
  verifyJsonWebToken(token: $token) {
    id
    name
    email
    role
  }
}
    `;

/**
 * __useVerifyJsonWebTokenQuery__
 *
 * To run a query within a React component, call `useVerifyJsonWebTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyJsonWebTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyJsonWebTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyJsonWebTokenQuery(baseOptions: Apollo.QueryHookOptions<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables> & ({ variables: VerifyJsonWebTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables>(VerifyJsonWebTokenDocument, options);
      }
export function useVerifyJsonWebTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables>(VerifyJsonWebTokenDocument, options);
        }
export function useVerifyJsonWebTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables>(VerifyJsonWebTokenDocument, options);
        }
export type VerifyJsonWebTokenQueryHookResult = ReturnType<typeof useVerifyJsonWebTokenQuery>;
export type VerifyJsonWebTokenLazyQueryHookResult = ReturnType<typeof useVerifyJsonWebTokenLazyQuery>;
export type VerifyJsonWebTokenSuspenseQueryHookResult = ReturnType<typeof useVerifyJsonWebTokenSuspenseQuery>;
export type VerifyJsonWebTokenQueryResult = Apollo.QueryResult<VerifyJsonWebTokenQuery, VerifyJsonWebTokenQueryVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken)
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: UserInput!) {
  createUser(input: $input)
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UserByIdDocument = gql`
    query UserById($id: String!) {
  getOne(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables> & ({ variables: UserByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export function useUserByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdSuspenseQueryHookResult = ReturnType<typeof useUserByIdSuspenseQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $input: UserUpdateInput!) {
  updateUser(id: $id, input: $input)
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;