import { useRouter } from 'next/navigation';

import { paths } from '@/paths';

export function useNavigateTo() {
    const router = useRouter();

    const navigateTo = (path: string) => {
        router.push(path);
        router.refresh();
    };

    const navigateToDashboardOverview = () => {
        navigateTo(paths.dashboard.overview);
    };

    const navigateToUsersList = () => {
        navigateTo(paths.dashboard.users.list);
    };

    const navigateToProductList = () => {
        navigateTo(paths.dashboard.products.list);
    };

    const navigateToProductCreate = () => {
        navigateTo(paths.dashboard.products.create);
    };

    const navigateToProductGroupList = () => {
        navigateTo(paths.dashboard.productGroups.list);
    };

    const navigateToArticleList = () => {
        navigateTo(paths.dashboard.articles.list);
    };

    const navigateToDocumentList = () => {
        navigateTo(paths.dashboard.documents.list);
    };

    return {
        navigateTo,
        navigateToDashboardOverview,
        navigateToUsersList,
        navigateToProductList,
        navigateToProductCreate,
        navigateToProductGroupList,
        navigateToArticleList,
        navigateToDocumentList,
    };
}
